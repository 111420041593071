import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setAuth } from "../../features/user/userAuth";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DashboardContext from "../../context/Dashboard/DashboardContext";
import "./Login.css";
import "../Register/Register.css";
import LazyLoadImage from "../LazyLoad/LazyLoad";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSetUser } = useContext(DashboardContext);
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isSpinnerActive, setisSpinnerActive] = useState(false);
  const [password, setPassword] = useState("");
  const [rememberMe, setrememberMe] = useState(false);
  const [key, setKey] = useState("guest");
  const [roleId, setRoleId] = useState(2);

  useEffect(() => {
    // Determine the tab based on the URL
    const pathname = location.pathname.toLowerCase();
    if (pathname.includes("/host")) {
      setKey("host");
      setRoleId(1);
    } else if (pathname.includes("/guest")) {
      setKey("guest");
      setRoleId(2);
    } else if (location.state?.userType) {
      const userType = location.state.userType;
      setKey(userType);
      setRoleId(userType === "host" ? 1 : 2);
    }
  }, [location]);

  useEffect(() => {
    const storedAuthData =
      localStorage.getItem("authData") || sessionStorage.getItem("authData");

    if (storedAuthData) {
      const authData = JSON.parse(storedAuthData);
      if (authData.user.roleId === roleId) {
        setEmail(authData.user.email || "");
        setPassword(authData.password || "");
        setrememberMe(authData.rememberMe || false);
        setKey(authData.user.roleId === 1 ? "host" : "guest");
      } else {
        setEmail("");
        setPassword("");
        setrememberMe(false);
        setKey(roleId === 1 ? "host" : "guest");
      }
    } else {
      setEmail("");
      setPassword("");
      setrememberMe(false);
      setKey(roleId === 1 ? "host" : "guest");
    }
  }, [roleId]);

  const handleLogin = async (e) => {
    e.preventDefault();

    const adminEmail = "techanzy@gmail.com";
    const adminPassword = "Good@123123";

    let roleIdToSend = roleId;

    if (roleId === 1) {
      if (email === adminEmail && password === adminPassword) {
        roleIdToSend = 3;
      }
    }

    try {
      setisSpinnerActive(true);
      let response = await axios({
        method: "post",
        url: process.env.REACT_APP_API_LOGIN,
        data: {
          email: email,
          password: password,
          rememberMe: rememberMe,
          roleId: roleIdToSend,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const isOtpVerified = response.data.isOtpVerified;
      if (response.status === 200) {
        const authData = {
          jwtToken: response.data.token,
          user: response.data.user,
          rememberMe,
          isOtpVerified: isOtpVerified,
          password: password,
          roleId: roleIdToSend,
          subscriptionExpired: response.data.subscriptionExpired,
          subscriptionEndDate: response.data.subscriptionEndDate,
          subscription: response.data.user.subscription,
        };

        dispatch(setAuth(authData));

        if (rememberMe) {
          localStorage.setItem("authData", JSON.stringify(authData));
        }

        if (isOtpVerified === false) {
          navigate("/verify-otp");
        } else {
          handleSetUser(response.data.user);
          if (response.data.user.roleId === 1) {
            const { subscriptionExpired } = response.data;
            if (subscriptionExpired) {
              navigate("/dashboard/update-subscription");
            } else {
              navigate("/dashboard");
            }
          } else if (response.data.user.roleId === 2) {
            navigate("/guest-dashboard");
          } else if (response.data.user.roleId === 3) {
            navigate("/admin-dashboard");
          }
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
    setisSpinnerActive(false);
  };

  const handleSelectTab = (k) => {
    setKey(k);
    setRoleId(k === "guest" ? 2 : 1);
  };

  return (
    <Container fluid className="register-screen">
      <LazyLoadImage
        effect="blur"
        className="logo"
        src={process.env.PUBLIC_URL + "/logo.png"}
        fluid={true.toString()}
      />

      <Tabs
        activeKey={key}
        id="uncontrolled-tab-example"
        onSelect={handleSelectTab}
        className="mb-3"
      >
        <Tab eventKey="guest" title="Guest Login">
          <Row>
            <Col md={6} className="animation-wrapper">
              <div className="login-left">
                <div className="sun">
                  <LazyLoadImage
                    effect="blur"
                    src={process.env.PUBLIC_URL + "/sun.png"}
                    fluid={true.toString()}
                  />
                </div>
                <div className="building">
                  <LazyLoadImage
                    effect="blur"
                    src={process.env.PUBLIC_URL + "/login-home.png"}
                    fluid={true.toString()}
                  />
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className="Login-right">
                <div className="sign-up-form">
                  <h2>Sign In</h2>
                  <p>Please fill your detail to access your account.</p>

                  <div className="sign-in-form-wrp">
                    <Form onSubmit={handleLogin}>
                      <Form.Group className="mb-3" controlId="formGridAddress1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formGridAddress2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          autoComplete="new-password"
                          required
                          type="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>

                      <div className="forgot-paswrd-div-n-remember">
                        <Form.Group className="mb-0" id="formGridCheckbox">
                          <Form.Check
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => setrememberMe(e.target.checked)}
                            label="Remember me"
                          />
                        </Form.Group>
                        <p className="forgot-password">
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </p>
                      </div>

                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSpinnerActive}
                      >
                        {isSpinnerActive ? (
                          <Spinner animation="border" size="sm" />
                        ) : null}
                        &nbsp; Sign-in
                      </Button>
                    </Form>
                  </div>
                  <div className="login-signup-form-btm-sec">
                    <p className="have-an-account">
                      Don’t have an account?{" "}
                      <Link to="/register" state={{ userType: "guest" }}>
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="host" title="Host Login">
          <Row>
            <Col md={6} className="animation-wrapper">
              <div className="login-left">
                <div className="sun">
                  <LazyLoadImage
                    effect="blur"
                    src={process.env.PUBLIC_URL + "/sun.png"}
                    fluid={true.toString()}
                  />
                </div>
                <div className="building">
                  <LazyLoadImage
                    effect="blur"
                    src={process.env.PUBLIC_URL + "/login-home.png"}
                    fluid={true.toString()}
                  />
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className="Login-right">
                <div className="sign-up-form">
                  <h2>Sign In</h2>
                  <p>Please fill your detail to access your account.</p>

                  <div className="sign-in-form-wrp">
                    <Form onSubmit={handleLogin}>
                      <Form.Group className="mb-3" controlId="formGridAddress1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formGridAddress2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          autoComplete="new-password"
                          required
                          type="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>

                      <div className="forgot-paswrd-div-n-remember">
                        <Form.Group className="mb-0" id="formGridCheckbox">
                          <Form.Check
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => setrememberMe(e.target.checked)}
                            label="Remember me"
                          />
                        </Form.Group>
                        <p className="forgot-password">
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </p>
                      </div>

                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSpinnerActive}
                      >
                        {isSpinnerActive ? (
                          <Spinner animation="border" size="sm" />
                        ) : null}
                        &nbsp; Sign-in
                      </Button>
                    </Form>
                  </div>
                  <div className="login-signup-form-btm-sec">
                    <p className="have-an-account">
                      Don’t have an account?{" "}
                      <Link to="/register" state={{ userType: "host" }}>
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Login;
